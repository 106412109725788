<template>
  <div>
    <data-list-users v-bind:roles="roles" v-bind:loadingRoles="loadingRoles" v-bind:users="users"
      v-bind:loadingUsers="loadingUsers" v-bind:headersRoles="headersRoles" v-bind:headersUsers="headersUsers"
      v-bind:nextId="nextId" v-bind:addUserReq="addUserReq" v-bind:deleteUserReq="deleteUserReq"
      v-bind:getRolesList="getRolesList" v-bind:getUsersList="getUsersList" />
    <data-list-roles-rdo
      v-bind:rdoRoles="rdoRoles"
      v-bind:loadingRdoRoles="loadingRdoRoles"
      v-bind:headersRdo="headersRdo"
      v-bind:listRdo="listRdo"
      v-bind:rdoSelected="rdoSelected"
      v-bind:getRolesList="getRolesList"
      v-bind:updateRdoSelect="updateRdoSelect"
      v-bind:sendRight="sendRight "      
      />
  </div>
</template>

<script>
import DataListUsers from "../components/DataListUsers.vue";
import DataListRolesRdo from "../components/DataListRolesRdo.vue";
import AdminManagement from "../scripts/adminManagement";
export default {
  name: "ADMIN",
  mixins: [AdminManagement],
  components: {
    DataListUsers,
    DataListRolesRdo,
  },
  data() {
    return {
      rdoRoles:[],
      loadingRdoRoles: false,
      rdoSelected: null,
      listRdo: [],
      loadingRdo: true,
      nextId: 0,
      roles: [],
      loadingRoles: true,
      users: [],
      loadingUsers: true,
      headersRdo: [
        {
          text: "Email",
          align: "left",
          value: "Email",
          sortable: false,
        },
        {
          text: "Default",
          align: "left",
          value: "Default",
          sortable: false,
        },
        {
          text: "Qualif",
          align: "left",
          value: "Qualif",
          sortable: false,
        },
        {
          text: "Inventaire",
          align: "left",
          value: "Inventaire",
          sortable: false,
        },
        {
          text: "Purge",
          align: "left",
          value: "Purge",
          sortable: false,
        },
        {
          text: "TOV",
          align: "left",
          value: "TOV",
          sortable: false,
        },
        {
          text: "PO",
          align: "left",
          value: "PO",
          sortable: false,
        },
        { value: "actions", sortable: false, align: 'end' },
      ],
      headersRoles: [
        {
          text: "Name",
          align: "left",
          value: "_fieldsProto.name.stringValue",
          sortable: false,
        },
        {
          text: "ID",
          align: "left",
          value: "_fieldsProto.id.integerValue",
          sortable: false,
        },
        {
          text: "Description",
          align: "left",
          value: "_fieldsProto.description.stringValue",
          sortable: false,
        },
        { value: "actions", sortable: false, align: 'end' },
      ],
      headersUsers: [
        {
          text: "Email",
          align: "left",
          value: "_ref._path.segments[1]",
        },
        {
          text: "Nom",
          align: "left",
          value: "_fieldsProto.name.stringValue",
        },
        {
          text: "Prenom",
          align: "left",
          value: "_fieldsProto.firstname.stringValue",
        },
        {
          text: "Admin PRP",
          align: "left",
          value: "_fieldsProto.isAdminRDExpo.booleanValue",
        },
        { value: "actions", sortable: false },
      ],
    };
  },
  mounted: async function () {
    setTimeout(() => {
      this.loadingRdo = false;
      this.loadingRoles = false;
      this.loadingUsers = false;
    }, 5000);
    await this.getRdoList();
    await this.getRolesList();
    await this.getUsersList();
  },

  methods: {
    async refresh() {
      setTimeout(() => {
        this.loadingRdo = false;
        this.loadingRoles = false;
        this.loadingUsers = false;
      }, 5000);
      await this.getRdoList();
      await this.getRolesList();
      await this.getUsersList();
    },
  },

};
</script>