<template>
  <div>
      <nav-bar />
      <v-container>
          Page Supervision
      </v-container>
  </div>
</template>

<script>
import NavBar from "../components/rdoNavBar.vue";
export default {
  name: "SUPV",
  components: {
      NavBar,
  },
};
</script>

<style></style>
