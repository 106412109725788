<template>
  <v-container>
    <v-row class="d-flex align-center">
      <v-col cols="auto" class="pl-3">
        <div class="my-3">
          <v-btn small @click="goto('/supv')">
            <v-icon>mdi-finance</v-icon>
          </v-btn>
        </div>
        <div class="my-3">
          <v-btn small @click="refresh()">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <v-textarea v-model="apiUrl" label="API" readonly no-resize rows="2"></v-textarea>
      </v-col>
      <v-col cols="auto">
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="3">
        <v-select :disabled="loadingList" :loading="loadingList" :items="listRdo" label="Select"
          item-text="_fieldsProto.Nomenclature GCP.stringValue" return-object single-line
          v-on:change="updateRdoSelected">
          <template slot="selection" slot-scope="data">
            {{ data.item["_fieldsProto"]["Nomenclature GCP"]["stringValue"] }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item["_fieldsProto"]["Nomenclature GCP"]["stringValue"] }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="auto">
        <v-btn color="info" small @click="dialogInfoRdo()">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog :value="dialogInfo" :overlay="false" max-width="500px" transition="dialog-transition">
      <v-card v-if="this.dialogInfo" v-click-outside="dialogInfoRdo">
        <v-card-title primary-title>
          {{ rdoSelected._fieldsProto["RDO"].stringValue.toUpperCase() }}
        </v-card-title>
        <v-card-text>
          <ul>
            <li v-for="(value, name) in sortedRdoInfo" :key="name">
              <b>{{ name }}</b>
              : {{ value }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn color="orange" :href="rdoSelected['_fieldsProto']['Lien Gitlab'].stringValue" target="_blank">
            <v-icon>mdi-gitlab</v-icon> Gitlab
          </v-btn>
          <v-btn color="green" :href="rdoSelected['_fieldsProto']['Lien Data Centric'].stringValue" target="_blank">
            <v-icon>mdi-google-spreadsheet</v-icon> Sheet
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogInfoRdo()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "rdo-api-selection",
  props: {
    loadingList: Boolean,
    listRdo: Array,
    apiParams: Object,
    apiRDO: Object,
    addedParams: Array,
  },
  data() {
    return {
      rdoSelected: null,
      dialogInfo: false,
    };
  },
  methods: {
    dialogInfoRdo() {
      this.dialogInfo ? (this.dialogInfo = false) : (this.dialogInfo = true);
    },
    updateRdoSelected(a) {
        this.rdoSelected = a;
        this.$emit("updateRdoSelected", a);
    },
    goto(element) {
      this.$router.push(element).catch(() => { });
      if (element != "admin" || element != "po") {
        this.$store.dispatch("setRdoSelected", null);
      }
    },
    refresh() {
      this.$emit("updateRdoSelected", this.rdoSelected);
        
    }
   
  },
  computed: {
    apiUrl: function () {
      const api = this.apiRDO;
      const searchParams = new URLSearchParams();
      for (const key of Object.keys(this.apiParams)) {
        const param = this.apiParams[key];
        searchParams.append(key, param);
      }
      if (this.addedParams.length) {
        for (const index of this.addedParams) {
          searchParams.append(index.key, index.value);
        }
      }
      const params = decodeURIComponent(searchParams.toString());
      if (api == undefined) {
        return "Pas de RDO sélectionné \nou pas d'API output pour ce RDO";
      } else {
        return this.apiRDO.output + "/v1/output?" + params;
      }
    },

    sortedRdoInfo: function () {
      var sel = this.rdoSelected;
      if (sel !== null) {
        var obj = {};
        Object.keys(sel["_fieldsProto"]).forEach((el) => {
          obj[el] = sel["_fieldsProto"][el].stringValue;
        });
        var sorted = JSON.parse(
          JSON.stringify(obj, [
            "RDO",
            "Libellé",
            "Propriétaire RDO",
            "PO Data",
            "Archi Fonctionnel",
            "Archi Data",
            "EasyVista",
            "Type collecte",
            "Chaîne de valeur",
          ])
        );
        return sorted;
      } else return null;
    },
  },
};
</script>

<style></style>
