const axios = require("axios");
//used in webapp/src/view/login.vue

import store from "../store/store";

const url = `${store.getters.apiUrl}`;

export default {
  methods: {
    async getUser(user) {
      let mail = user.email;
      let nom = user.displayName.split(" ")[1];
      let prenom = user.displayName.split(" ")[0];
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/users/${mail}`;
      await axios
        .get(request, config)
        .then(async (results) => {
          if (!Object.keys(results.data).length) {
            await this.newUserReq(mail, nom, prenom, false);
            console.log("existe pas");
          }
          else {
            this.userGCP = results.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async newUserReq(mail, nom, prenom, admin) {
      let obj = {
        mail: mail,
        name: nom.toUpperCase(),
        firstname: prenom.toUpperCase(),
        isAdminRDExpo: admin,
      };
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/users`;
      await axios
        .post(request, obj, config)
        .then((results) => {
          this.userGCP.id = results.data.id;
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },
};
