<template>
  <v-container>
    <v-row class="d-flex">
      <v-col cols=3>
        <v-text-field 
        v-model="search"
        label="Rechercher"
        hide-details
        append-icon="mdi-magnify"
        @click:append="
            addSearch(search)
          "
          @keyup.enter="
            addSearch(search)
          "
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <treeselect
          class="v-input__control"
          v-model="keySelected"
          :multiple="false" 
          :options="nestedList"
          placeholder="Paramètres"
          @input="updateType"
        />
      </v-col>
      <v-col cols="1">
        <v-select
          :items="connectors"
          v-model="connectorSelected"
          item-text="[1]"
          return-object
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="valueParam"
          label="Valeur"
          hide-details
          append-icon="mdi-plus-circle-outline"
          :type="dataType"
          @click:append="
            addParameter(keySelected, connectorSelected, valueParam)
          "
          @keyup.enter="
            addParameter(keySelected, connectorSelected, valueParam)
          "
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-combobox
          v-if="haveParams"
          flat
          multiple
          solo
          readonly
          hide-details
          append-icon
          chips
          :items="addedParams"
          :value="addedParams"
          background-color="background"
        >
          <template slot="selection" slot-scope="{ item, index }">
            <v-chip
              close
              small
              outlined
              color="primary lighten-2"
              @click:close="deleteParameter(index)"
            >
              <span> {{ item.key }}={{ item.value }} </span>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <!-- Boîte de dialogue - paramètre(s) manquant -->
    <v-dialog
      :value="dialogErrorParameter"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-card-title primary-title>Paramètre(s) manquant(s) !</v-card-title>
        <v-card-text>
          Il manque un ou plusieurs paramètre(s).
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="toggleDialogErrorParameter()">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
const moment = require("moment");

export default {
  name: "rdo-params",
  components: { Treeselect },
  props: {
    rdoKeys: Array,
    nestedList: Array,
    connectors: Array,
    addedParams: Array,
    rdoSelected: Object,
  },

  data() {
    return {
      keySelected: null,
      connectorSelected: null,
      valueParam: null,
      dataType: "",
      search: "",
      dialogErrorParameter: false,
      haveParams: false
    };
  },

  methods: {
    addParameter(parameter, connector, value) {
      if (parameter === null || connector === null || value === null) {
        this.dialogErrorParameter = true
        return
      }
      if (
        this.dataType === "date" &&
        this.connectorSelected !== "!" &&
        this.connectorSelected !== "="
      ) {
        value = `${moment(value).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")}`;
      }
      this.$emit("addParameter", parameter, connector[0], value);
      this.keySelected = null
      this.connectorSelected = null
      this.valueParam = null
      this.haveParams = true
    },

    toggleDialogErrorParameter() {
      this.dialogErrorParameter = false
    },

    addSearch(parameter) {
      this.$emit("addSearch", parameter);
    },

    deleteParameter(parameter) {
      this.$emit("deleteParameter", parameter);
      if (this.addedParams.length === 0) {
        this.haveParams = false
      }
    },

    updateType(a) {
      const typeSelected = this.rdoKeys.filter(key => key.text === a)
      this.dataType = typeSelected.type;
    },
  },
  watch: {
    rdoSelected: function(newRdo, oldRdo) {
      this.keySelected = null
      this.connectorSelected = null
      this.valueParam = null
    }
  }
};
</script>

<style></style>
