<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <app-bar />
    <v-main>  
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";

export default {
  name: "RDExpo",

  components: {
    AppBar,
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Portal RDO Platform";
      },
    },
  },
};
</script>

<style lang="scss">
.v-autocomplete.v-input > .v-input__control > .v-input__slot, 
.vue-treeselect {
  cursor: auto !important;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-color: rgb(118, 118, 118);
}
.v-input__slot, .vue-treeselect, .vue-treeselect__control {
  background-color: #fafafa !important;
}

.vue-treeselect {
  max-width: 100%;
}

.vue-treeselect__control{
  border: none !important;
  padding: 0;
  margin: 0 auto !important;
  color: rgba(0,0,0,.99);
}
</style>
