import Vue from "vue";
import VueRouter from "vue-router";
import RDO from "../views/rdo.vue";
import SUPV from "../views/supv.vue";
import LOGIN from "../views/login.vue";
import POMANAGE from "../views/po_manage.vue";
import ADMIN from "../views/admin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/rdo",
    name: "RDO",
    component: RDO,
    meta: { title: "PRP" },
  },
  {
    path: "/po",
    name: "POMANAGE",
    component: POMANAGE,
    meta: { title: "Po - PRP" },
  },
  {
    path: "/admin",
    name: "ADMIN",
    component: ADMIN,
    meta: { title: "Admin - PRP" },
  },
  {
    path: "/supv",
    name: "SUPV",
    component: SUPV,
    meta: { title: "Supervision - PRP" },
  },
  {
    path: "/login",
    name: "LOGIN",
    component: LOGIN,
    meta: { title: "LOGIN - PRP" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
