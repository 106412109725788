import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import JsonViewer from "vue-json-viewer";


Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  var loggedIn = store.getters.user.loggedIn;
  if (!loggedIn && to.path !== "/login") {
    try {
      next("/login");
    } catch (error) {
      console.log(error);
    }
  } else if (to.path == "/login" && loggedIn) {
    next("/rdo");
  } else {
    next();
  }
});

Vue.use(JsonViewer);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
