<template>
  <v-container>
    <v-navigation-drawer app permanent clipped width="200" color="background">
      <v-layout column fill-height justify-space-between>
        <v-list v-if="this.$router.currentRoute.path === '/rdo'">
          <v-list-item v-if="this.subRequestsLength" @click="toggleDialogRequests()">
            <v-list-item-content>
              <v-list-item-title>
                <v-badge :content="subRequestsLength" :value="subRequestsLength" overlap offset-x="0">
                  <v-icon>mdi-lock-open-plus</v-icon>
                  Demandes
                </v-badge>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="toggleDialogPurge()"
            v-if="this.rdoSelected !== null && this.apiRDO !== undefined && isRightPurge() == true"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>mdi-file-document-remove</v-icon> Purge
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="toggleDialogreplay()"
            v-if="this.rdoSelected !== null && this.apiRDO !== undefined && isRightReplay() == true"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>mdi-file-document-remove</v-icon> Reprise
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="toggleDialogAlim()"
            v-if="this.rdoSelected !== null && this.apiRDO !== undefined && isRightAlim() == true && (env === 'HPRD' || env === 'DEV')"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>mdi-file-document-remove</v-icon> Alim Prod - Qualif
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="toggleDialogTov()"
            v-if="(this.rdoSelected !== null && this.rdoSelected[`_fieldsProto`][`Nomenclature GCP`][`stringValue`].includes('tableofvalue')) && this.apiRDO !== undefined && isRightTov() == true"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>mdi-update</v-icon>
                MAJ TOV
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list justify-end dense v-if="this.$router.currentRoute.path !=='/supv'">
          <v-divider></v-divider>
          <v-list-item @click="toggleDialogSupport()">
            <v-list-item-content>
              <v-list-item-title>Support</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-navigation-drawer>

    <!-- DIALOGS SERVICES -->

    <serv-purge v-bind:dialogPurge="dialogPurge" v-bind:toggleDialogPurge="toggleDialogPurge"
      v-bind:rdoSelected="rdoSelected" v-bind:addedParams="addedParams" v-bind:apiRDO="apiRDO" v-bind:numberOfDoc="numberOfDoc" @purge="purge" />

    <serv-replay v-bind:dialogreplay="dialogreplay" v-bind:toggleDialogreplay="toggleDialogreplay"
      v-bind:rdoSelected="rdoSelected" v-bind:apiRDO="apiRDO" v-bind:numberOfDoc="numberOfDoc" />

    <serv-alim v-bind:dialogAlim="dialogAlim" v-bind:toggleDialogAlim="toggleDialogAlim"
      v-bind:rdoSelected="rdoSelected" v-bind:addedParams="addedParams" v-bind:apiRDO="apiRDO" v-bind:numberOfDoc="numberOfDoc" />

    <subscribe-requests v-bind:dialogRequests="dialogRequests" v-bind:toggleDialogRequests="toggleDialogRequests"
      v-bind:subRequests="subRequests" @answerRequest="answerRequest" />

    <serv-tov v-bind:dialogTov="dialogTov" v-bind:toggleDialogTov="toggleDialogTov" v-bind:rdoSelected="rdoSelected" />

    <!-- DIALOG SUPPORT -->
    <v-dialog :value="dialogSupport" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card flat fluid color="background">
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>Support</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="toggleDialogSupport()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="pt-3 d-flex justify-space-around">
          <v-col cols="12" sm="6" md="6">
            <v-card>
              <v-card-title primary-title>Contacts</v-card-title>
              <v-card-text class="d-flex justify-center align-center">
                <v-img
                  class="mr-2"
                  lazy-src="https://play-lh.googleusercontent.com/cF_oWC9Io_I9smEBhjhUHkOO6vX5wMbZJgFpGny4MkMMtz25iIJEh2wASdbbEN7jseAx"
                  aspect-ratio="1"
                  max-height="25"
                  max-width="25"
                  src="https://play-lh.googleusercontent.com/cF_oWC9Io_I9smEBhjhUHkOO6vX5wMbZJgFpGny4MkMMtz25iIJEh2wASdbbEN7jseAx"
                ></v-img>
                Support RDO
              </v-card-text>
              <v-card-text class="d-flex justify-center align-center">
                <v-img
                  class="mr-2"
                  lazy-src="https://img.freepik.com/icones-gratuites/e-mail_318-859700.jpg?w=2000"
                  aspect-ratio="1"
                  max-height="25"
                  max-width="25"
                  src="https://img.freepik.com/icones-gratuites/e-mail_318-859700.jpg?w=2000"
                ></v-img>
                <a href="mailto:fr-liste_gcp_admin_rdo@auchan.fr">
                  fr-liste_gcp_admin_rdo@auchan.fr
                </a> 
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-card>
              <v-card-title primary-title>
                Documentation + Tutoriel
              </v-card-title>
              <v-card-text class="d-flex justify-center align-center">
                <v-img
                  class="mr-2"
                  lazy-src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Google_Sites_2020_Logo.svg/langfr-800px-Google_Sites_2020_Logo.svg.png"
                  max-width="25"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Google_Sites_2020_Logo.svg/langfr-800px-Google_Sites_2020_Logo.svg.png"
                ></v-img>
                <a href="https://sites.google.com/partner.auchan.fr/datacentricgsite/home?authuser=0" target="_blank">
                  Portail Documentaire des RDOs
                </a>
              </v-card-text>
              <v-card-text class="d-flex justify-center align-center">
                <v-img
                  class="mr-2"
                  max-width="50"
                  cover
                  :src="getLogoGaia()"
                ></v-img>
                <a href="https://gaia.auchan.fr/service-home/1AvEmT6bMBKohHBVeCKB" target="_blank">
                  Documentation GAIA
                </a>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "../store/store.js";
import ServReplay from "./services/Serveplay.vue";
import ServAlim from "./services/ServAlim.vue";
import ServPurge from "./services/ServPurge.vue";
import SubscribeRequests from "./services/SubscribeRequests.vue";
import ServTov from "./services/ServTov.vue";
import services from "../scripts/services";
import subscribe from "../scripts/subscribe";

export default {
  name: "rdo-nav-bar",
  components: {
    ServPurge,
    ServReplay,
    ServAlim,
    SubscribeRequests,
    ServTov,
  },
  mixins: [services, subscribe],
  data() {
    return {
      env: store.getters.env,
      dialogSupport: false,
      dialogPurge: false,
      dialogreplay: false,
      dialogAlim: false,
      dialogRequests: false,
      dialogTov: false,
    };
  },
  props: {
    rdoSelected: Object,
    apiParams: Object,
    apiRDO: Object,
    addedParams: Array,
    subRequests: Array,
    numberOfDoc: Number,
  },
  methods: {
    isRightPurge() { //Fonction de test catch("TypeError: Cannot read properties of null")
      try {
        if (this.$store.getters.user.currentRights.Purge == true) {
          return true
        }
        else {
          return false
        }
      }
      catch (error) {
        if (error instanceof TypeError) {
          return false
        }
        else {
          console.log("Probléme non reconnu " + error)
        }
      }
    },
    isRightReplay() { //Fonction de test catch("TypeError: Cannot read properties of null")
      try {
        if (this.$store.getters.user.currentRights.Purge == true) {
          return true
        }
        else {
          return false
        }
      }
      catch (error) {
        if (error instanceof TypeError) {
          return false
        }
        else {
          console.log("Probléme non reconnu " + error)
        }
      }
    },
    isRightAlim() { //Fonction de test catch("TypeError: Cannot read properties of null")
      try {
        if (this.$store.getters.user.currentRights.Alim == true) {
          return true
        }
        else {
          return false
        }
      }
      catch (error) {
        if (error instanceof TypeError) {
          return false
        }
        else {
          console.log("Probléme non reconnu " + error)
        }
      }
    },
    isRightTov() {
      { //Fonction de test catch("TypeError: Cannot read properties of null")
        try {
          if (this.$store.getters.user.currentRights["MAJ TOV"] == true) {
            return true
          }
          else {
            return false
          }
        }
        catch (error) {
          if (error instanceof TypeError) {
            return false
          }
          else {
            console.log("Probléme non reconnu " + error)
          }
        }
      }
    },
    toggleDialogSupport() {
      this.dialogSupport = !this.dialogSupport;
    },
    toggleDialogPurge() {
      this.dialogPurge = !this.dialogPurge;
    },
    toggleDialogreplay() {
      this.dialogreplay = !this.dialogreplay;
    },
    toggleDialogAlim() {
      this.dialogAlim = !this.dialogAlim;
    },
    toggleDialogRequests() {
      this.dialogRequests = !this.dialogRequests;
    },
    toggleDialogTov() {
      this.dialogTov = !this.dialogTov;
    },
    getLogoGaia() {
      return require("../assets/Gaia.png");
    }
  },
  computed: {
    subRequestsLength: function () {
      return this.subRequests.length;
    },
  },

};
 
</script>

<style></style>
