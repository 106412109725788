<template>
  <div>
    <v-dialog
      :value="dialogRequests"
      scrollable
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Demandes
        </v-card-title>
        <v-card v-for="request in subRequests" :key="request.id_request" hover>
            <v-card-title secondary-title>
                Demande de la part de {{request.mail_applicant}}.<p></p>
                Il souhaiterais accéder à la fonctionnalité suivante : {{request.future_role_name}}<p></p>
                Sur le RDO : {{request.rdo}} | ID de la demande : {{request.id_request}}
            </v-card-title>
            <v-card-actions>
                <v-btn color="success" @click="answerRequest(request, true)">Accepter</v-btn>
                <v-btn color="error" @click="answerRequest(request, false)">Refuser</v-btn>
            </v-card-actions>
        </v-card>


      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "subscribe-requests",
  props: {
    dialogRequests: Boolean,
    toggleDialogRequests: Function,
    subRequests: Array,
  },
  methods: {
    answerRequest(subRequest, accepted) {
        this.$emit("answerRequest", subRequest, accepted);
    }
  },
};
</script>

<style></style>
