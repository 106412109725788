//used in webapp/src/view/rdo.vue
import store from "./../store/store";
import empty from "is-empty";
export default {
  methods: {
    async updateRdoSelected(variable) {
      this.rdoSelected = variable;
      this.$store.dispatch("setRdoSelected", variable);
      try {
        let rdo =
          this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"];
        await this.getRDORole(rdo);
        let projet = null;
        if (store.getters.env == "HPRD") {
          if (rdo.includes("gca") || rdo.includes("gcas") || rdo.includes("aco") || rdo.includes("step")) {
            projet = this.listUrl.find((element) =>
              element.projectId.includes("arf-rdo-" + rdo)
            );
          }
          else {
            projet = this.listUrl.find((element) =>
              element.projectId.includes("arf-rdo-" + rdo + "-hprd")
            );
          }

        }
        else {
          projet = this.listUrl.find((element) =>
            element.projectId.includes("-" + rdo))
        }

        if (projet !== undefined) {
          if (projet.input !== undefined && projet.output !== undefined) {
            this.apiRDO = projet;
            await this.getRDO(projet.output, this.apiParams);
          } else {
            console.log("Pas d'API input/output sur ce projet");
            this.apiRDO = undefined;
            this.RDO = [];
          }
        } else {
          console.log("Ce projet n'existe pas");
          this.apiRDO = undefined;
          this.RDO = [];
        }
      }
      catch (error) {
        if (!(error instanceof TypeError)) {
          console.log(error)
        }
      }
    },

    async refreshRdo() {
      if (this.apiRDO.output !== undefined) {
        await this.getRDO(this.apiRDO.output, this.apiParams);
      }
    },

    async addParameter(parameter, connector, value) {
      if (parameter !== null && connector !== null && value !== null) {
        let key = parameter + connector;
        for (const param of Object.keys(this.apiParams)) {
          let split = param.split(/[:!]/)[0];
          if (parameter === split) delete this.apiParams[param];
        }
        this.addedParams.push({ key: key, value: value });
        await this.getRDO(this.apiRDO.output, this.apiParams);
      }
    },

    async addSearch(parameter) {
      if (parameter == "") {
        this.$delete(this.apiParams, "default_operator");
        this.$delete(this.apiParams, "q");
      } else {
        this.$set(this.apiParams, "default_operator", "and");
        this.$set(this.apiParams, "q", "*" + parameter + "*");
      }
      await this.getRDO(this.apiRDO.output, this.apiParams);
    },

    async deleteParameter(index) {
      this.addedParams.splice(index, 1);
      await this.getRDO(this.apiRDO.output, this.apiParams);
    },

    async updateDataRDO(id) {
      await this.getDataRDO(this.apiRDO.output, id.metadata.technicalId);
    },

    addMetadata() {
      return [
        { text: 'processDate', type: 'date' },
        { text: 'processId', type: 'string' },
        { text: 'publishDate', type: 'date' },
        { text: 'technicalId', type: 'string' }
      ]
    },

    addNestedMetadata(list) {
      const metadataList = this.addMetadata()
      metadataList.forEach(element => {
        list.push({ id: element.text, label: element.text })
      });
    },

    getNestedParamList(schema, parentKey, arrayCount) {
      arrayCount = arrayCount || 0;
      return Object.keys(schema.properties).reduce((acc, key) => {
        const prop = schema.properties[key];
        const parent = { id: key, label: key, children: [], type: prop.type };
        let newKey = parentKey !== "" ? `${parentKey}.${key}` : key;
        if (prop && prop.type === "object") {
          parent.children = this.getNestedParamList(prop, newKey, arrayCount);
          acc.push(parent);
        } else if (prop && prop.type === "array") {
          const node = empty(prop) && empty(prop.item)
          if (!node && (prop.items.type === 'object' || prop.items.type === 'array')) {
            newKey = arrayCount == 0 ? '{' + newKey : newKey
            arrayCount++;
            parent.children = this.getNestedParamList(prop.items, newKey, arrayCount);
            acc.push(parent);
          }
        } else {
          let parentId
          if (parentKey && parentKey.startsWith('{')) parentId = `${parentKey}}.${key}`;
          else parentId = parentKey !== "" ? `${parentKey}.${key}` : key;
          this.rdoKeys.push({ text: parentId, type: prop.type })
          acc.push({ id: parentId, label: key });
        }
        return acc;
      }, []);
    },
  },
};
