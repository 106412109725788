//used in webapp/src/views/rdo.vue

const axios = require("axios");
const empty = require('is-empty');
import store from "../store/store";

const url = `${store.getters.apiUrl}`;

export default {
    methods: {
        getListRdo() {
            let config = {
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                },
            };
            let request = `${url}/rdo`;
            axios
                .get(request, config)
                .then((results) => {
                    let tmp = [];
                    results.data.forEach((element) => {
                        tmp.push(element);
                    });
                    this.listRdo = tmp;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async getRDO() {
            this.loadingRDO = true;
            this.RDO.splice(0);
            let api = this.apiRDO.output;
            let apiParams = this.apiParams;
            let addedParams = this.addedParams;
            const searchParams = new URLSearchParams();
            for (const key of Object.keys(apiParams)) {
                const param = apiParams[key];
                searchParams.append(key, param);
            }
            if (addedParams.length) {
                for (const index of addedParams) {
                    searchParams.append(index.key, index.value);
                }
            }
            let api2 = api.replace("https://", "");
            let config = {
                params: searchParams,
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                },
            };

            let request = `${url}/rdo/${api2}`;
            axios
                .get(request, config)
                .then((results) => {
                    console.log('results.headers => ' + JSON.stringify(results.headers));
                    this.RDO = results.data;

                    this.contentRange = parseInt(results.headers["content-length"])
                    console.log('this.contentRange => ' + this.contentRange);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingRDO = false;
                    try {
                        this.getKeys(api2);
                    } catch {
                        console.log("Setting Failed - Pas de document dans ce RDO");
                    }
                });
        },

        async getKeys(api) {
            let config = {
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                },
            };

            let request = `${url}/schema/${api}`;

            await axios
                .get(request, config)
                .then((results) => {
                    if (!empty(results.data)) {
                        this.rdoKeys = this.addMetadata();
                        this.nestedList = this.getNestedParamList(results.data.schema, "")
                        this.addNestedMetadata(this.nestedList)
                        this.nestedList.sort((a, b) => (a.text > b.text ? 1 : -1))
                    } else {
                        this.rdoKeys = this.addMetadata();
                    }
                })
                .catch((error) => {
                    console.log("[rdoRequests.js] - error for update schema for parameter : ", error);
                });
        },

        async getDataRDO(api, id) {
            const searchParams = new URLSearchParams();
            searchParams.append("technicalId", id);
            let api2 = api.replace("https://", "");
            let config = {
                params: searchParams,
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                },
            };
            let request = `${url}/rdo/${api2}`;
            await axios
                .get(request, config)
                .then((results) => {
                    this.dataRDO = results.data[0];
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async getRDORole(rdo) {
            let config = {
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                },
            };
            let request = `${url}/roles_rdo/${rdo}`;
            axios
                .get(request, config)
                .then(async(results) => {
                    if (results != null) {
                        let x = results.data;
                        let id = store.getters.user.data.id;
                        if (Object.prototype.hasOwnProperty.call(x, parseInt(id))) {
                            await store.dispatch("setCurrentRole", x[id].roles);
                            this.getRights(x[id].roles, rdo);
                        } else {
                            await store.dispatch("setCurrentRole", 0);
                            this.getRights(0);
                        }
                    }
                })
                .catch((error) => {
                    if (error instanceof TypeError) {
                        console.log("Pas de rôles en BDD")
                        return error
                    } else {
                        console.log("Probléme non reconnu : " + error)
                    }
                });
        },

        getRights(id, rdo) {
            let config = {
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                },
            };
            let request = `${url}/roles/${id}`;
            axios
                .get(request, config)
                .then(async(results) => {
                    console.log("")
                    console.log("[ rdoRequests.js ] - getRights() - results.data : ", results.data)
                    let obj = {};
                    if (rdo.includes("tableofvalue")) {
                        obj["MAJ TOV"] = results.data["right-tov"] || false;
                    }
                    obj["Admin RDExpo"] = results.data["right-admin"] || false;
                    obj["PO Management"] = results.data["right-po"] || false;
                    obj["Inventaire"] = results.data["right-inventory"] || false;
                    obj["Purge"] = results.data["right-purge"] || false;
                    obj["Replay"] = results.data["right-replay"] || false;
                    obj["Alim"] = results.data["right-alim"] || false;
                    obj["Prod vers Qualif"] = results.data["right-qualif"] || false;
                    obj["Supervision Fonctionnelle"] =
                        results.data["right-supv-fonc"] || false;
                    obj["Supervision Technique"] =
                        results.data["right-supv-tech"] || false;

                    let sorted = JSON.parse( //Possibilité de rajouter les demandes de droits ici
                        JSON.stringify(obj, [
                            "Purge",
                            "MAJ TOV",
                            "Supervision Fonctionnelle",
                            "Supervision Technique",
                            "Replay",
                            "Alim"
                        ])
                    );
                    await store.dispatch("setCurrentRights", sorted);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        getListUrl() {
            let config = {
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                },
            };
            let request = `${url}/rdo_url`;
            axios
                .get(request, config)
                .then((results) => {
                    this.listUrl = results.data;
                    this.loadingList = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async majTov(data) {
            const baseUrl = `${url}/majtov/`;
            const config = {
                method: "PATCH",
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            };
            const response = await fetch(baseUrl, config);
            return response;
        },

        async majTovProd(nameTov) {
            const baseUrl = `${url}/majtovprod/${nameTov}`;
            const config = {
                method: "PATCH",
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await fetch(baseUrl, config);
            return response;
        },

        async tovIsExist(technicalId) {
            const baseUrl = `${url}/tovexist/${technicalId}`;
            const config = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                    "Content-Type": "application/json",
                }
            };
            const response = await fetch(baseUrl, config);
            return response;
        },
    },
};