<template>
  <div>
    <v-app-bar app clipped-left dense dark color="primary" v-if="this.$store.getters.user.data !== null">
      <img :src="getLogo()" height="40" style="cursor: pointer" @click="goto('/rdo')" />
      <v-toolbar-title style="cursor: pointer" @click="goto('/rdo')" class="ml-2">Portal RDO Platform <small>({{ $store.getters.env }})</small>
      </v-toolbar-title>
      <v-toolbar-items v-if="this.$store.getters.user.isAdminRDExpo === true">
        <v-btn text @click="goto('/admin')">Admin Management</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-if="this.$store.getters.user.currentRole === '666'">
        <v-btn text @click="goto('/po')">Po Management</v-btn>
      </v-toolbar-items>


      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text @click="toggleDialogRights()">
          {{ user.data.displayName }}
        </v-btn>
        <v-btn text @click="logOut()">
          <v-img :src="user.data.icon" height="40" width="40" class="rounded-circle"></v-img>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-dialog :value="dialogRights" :overlay="false" max-width="500px" transition="dialog-transition">
      <v-card v-if="dialogRights" v-click-outside="toggleDialogRights">
        <v-card-title primary-title> Droits </v-card-title>
        <v-card-text v-if="user.currentRights === null">
          Veuillez sélectionner un RDO
        </v-card-text>
        <v-card-text v-else>
          <ul>
            <li v-for="(value, name) in user.currentRights" :key="name">
              <b :class="[value ? 'green--text' : 'red--text']">{{ name }}</b>
              :
              <span v-if="value">
                <v-icon>mdi-check</v-icon>
                <v-btn color="warning" text small @click="downgrade(name)" v-if="
                  name !== 'Supervision Fonctionnelle' &&
                  name !== 'Supervision Technique'
                ">Se désabonner</v-btn>
              </span>
              <span v-else>
                <v-icon>mdi-close</v-icon>
                <v-btn color="success" text @click="newSubRequest(name)">S'abonner</v-btn>
              </span>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "../firebase/firebase";
import subscribe from "../scripts/subscribe";
import store from "../store/store";
export default {
  name: "app-bar",
  mixins: [firebase, subscribe],
  data() {
    return {
      dialogRights: false,
    };
  },
  methods: {
    toggleDialogRights() {
      try {
        if (store.getters.rdoSelected != (null || undefined) && this.$router.currentRoute.path === '/rdo') {
          if (this.dialogRights) {
            this.dialogRights = false;
          } else {
            this.dialogRights = true;
          }
        }
      }
      catch (error) {
        if (error instanceof TypeError) {
          return false
        }
        else {
          console.log("Probléme non reconnu : " + error)
        }
      }
    },
    getLogo() {
      return require("../assets/logo" + this.$store.getters.env + ".png");
    },
    goto(element){
        this.$router.push(element).catch(()=>{});
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

<style></style>
