import Vue from "vue";
import colors from "vuetify/lib/util/colors";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: process.env.VUE_APP_API_URL,
    user: {
      loggedIn: false,
      data: null,
      currentRole: -1,
      currentRights: null,
      token: null,
      isAdminRDExpo: false,
    },
    rdoSelected: null,
    env: process.env.VUE_APP_ENV,
    mainColor: process.env.VUE_APP_ENV === 'DEV' ? colors.amber
      : process.env.VUE_APP_ENV === 'HPRD' ? colors.green
      : process.env.VUE_APP_ENV === 'PROD' ? colors.blue
      : colors.amber
  },
  getters: {
    apiUrl: (state) => {
      return state.apiUrl;
    },
    user: (state) => {
      return state.user;
    },
    role: (state) => {
      return state.role;
    },
    mainColor: (state) => {
      return state.mainColor;
    },
    env: (state) => {
      return state.env;
    },
    rdoSelected: (state) => {
      return state.rdoSelected;
    },
  },
  mutations: {
    updateApiUrl(state, arg) {
      state.apiUrl = arg;
    },
    updateLoggedIn(state, arg) {
      state.user.loggedIn = arg;
    },
    updateData(state, arg) {
      state.user.data = arg;
    },
    updateCurrentRole(state, arg) {
      state.user.currentRole = arg;
    },
    updateToken(state, arg) {
      state.user.token = arg;
    },
    updateCurrentRights(state, arg) {
      state.user.currentRights = arg;
    },
    updateIsAdminRDExpo(state, arg) {
      state.user.isAdminRDExpo = arg;
    },
    updateMainColor(state, arg) {
      state.mainColor = arg;
    },
    updateEnv(state, arg) {
      state.env = arg;
    },
    updateRdoSelected(state, arg) {
      state.rdoSelected = arg;
    },
  },
  actions: {
    async setApiUrl({ commit }, parameters) {
      commit("updateApiUrl", parameters);
    },
    async setUser({ commit }, parameters) {
      commit("updateLoggedIn", parameters !== null);
      if (parameters) {
        commit("updateData", parameters);
      }
    },
    async setToken({ commit }, parameters) {
      commit("updateToken", parameters);
    },
    async setCurrentRole({ commit }, parameters) {
      commit("updateCurrentRole", parameters);
    },
    async setCurrentRights({ commit }, parameters) {
      commit("updateCurrentRights", parameters);
    },
    async setIsAdminRDExpo({ commit }, parameters) {
      commit("updateIsAdminRDExpo", parameters);
    },
    async setMainColor({ commit }, parameters) {
      commit("updateMainColor", parameters);
    },
    async setEnv({ commit }, parameters) {
      commit("updateEnv", parameters);
    },
    async setRdoSelected({ commit }, parameters) {
      commit("updateRdoSelected", parameters);
    },
  },
});
